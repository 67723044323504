import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Http } from "../../http";

interface CreateFastOrderDataState {}

interface CreateFastOrderData {
  formData: FormData; // Adjust the type based on your actual FormData structure
}

export const createFastOrderRequest = createAsyncThunk<
  any, // Adjust the type based on your API response structure
  CreateFastOrderData,
  { rejectValue: any }
>("create_order", async (data, { rejectWithValue }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  };

  try {
    const response = await Http.post(
      `${process.env.REACT_APP_URL}api/fast_orders/create`,
      headers,
      data
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const createFastOrderSlice = createSlice({
  name: "create_order",
  initialState: {} as CreateFastOrderDataState,

  reducers: {}, // You can add reducers here if needed

  extraReducers: (builder) => {
    builder
      .addCase(createFastOrderRequest.pending, (state) => {})
      .addCase(createFastOrderRequest.fulfilled, (state, action) => {
        if (action.payload.success) {
        }
      })

      .addCase(createFastOrderRequest.rejected, (state, action) => {
        if (action.payload) {
          const { errors } = action.payload;

          if (errors) {
            // state.name_error = errors.name?.[0] || "";
            // state.phone_error = errors.phone?.[0] || "";
            // state.address_error = errors.address?.[0] || "";
            // state.email_error = errors.email?.[0] || "";
          }
        } else {
          console.error("Unexpected error structure:", action.payload);
        }
      });

    // ... (remaining code)
  },
});

export default createFastOrderSlice.reducer;
